import Imgix, { Picture, Source } from 'react-imgix';
import simpleImageType from '../../../../../utils/propTypes/simpleImageType';
import LinkResolver from '../../../../../components/LinkResolver';

const makeClasses = (props) => ({
  image: {
    maxWidth: '100%',
    height: 'auto',
    width: `${props.width}px`,
  },
  imageWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: props.alignment?.toLowerCase(),
  },
});

const SimpleImage = (props) => {
  const { image, alignment, link } = props;
  const classes = makeClasses({ alignment, width: image?.width });
  const imageUrl = image?.url;

  if (imageUrl) {
    return (
      <div css={classes.imageWrapper}>
        <LinkResolver {...link}>
          <Picture>
            <Source imgixParams={{ fit: 'max', fm: 'webp' }} htmlAttributes={{ type: 'image/webp' }} src={imageUrl} />
            <Imgix imgixParams={{ fit: 'max' }} css={classes.image} src={imageUrl} />
          </Picture>
        </LinkResolver>
      </div>
    );
  }
  return null;
};

SimpleImage.propTypes = simpleImageType;

SimpleImage.defaultProps = {
  image: null,
  alignment: 'Left',
  link: null,
};

export default SimpleImage;
